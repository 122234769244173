import React from "react";
import classes from "./success.module.css";
import classesDark from "./successDark.module.css";
import { useHistory} from "react-router-dom";
import Footer from "../UI/Footer/Footer";
const SuccessPage = (props) => {
    const history = useHistory();
   const returnHome=()=>{
    history.push("/");
   }
    let styles = classes;
    if (props.theme) {
        styles = classes;
    } else {
        styles = classesDark; 
    }
    return(
        <>
        <div className={styles.successSection}>
  <div className={styles.successContainer}>
    <h1 className={styles.successHeader}>Registered Successfully !</h1>
    <p className={styles.successMessage}> <br /> Recruitment round will take place on 19th September at 4:00 pm.
</p>
<br/><p>Follow further steps provided in the mail.</p>
    <button onClick={returnHome} className={styles.successButton}>Return to Home</button>
    
  </div>
  <h5 className={styles.successFooter}>
    <div className={styles.footerText}>If you have any questions or concerns, feel free to contact the coordinators. <br/>
</div></h5>
  </div>
   <Footer theme={props.theme} />
   </>
  )
}

export default SuccessPage;