import React, { useEffect, useCallback } from "react";
import { useState } from "react";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Container } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
// import Radio from "@mui/material/Radio";
// import RadioGroup from "@mui/material/RadioGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import classesDark from "./formDark.module.css";
import classes from "./form.module.css";
import logo from "../../assets/Logo/gdsc 1.svg";
import axios from "axios";
// import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Footer from "../UI/Footer/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SuccessPage from "./Success";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { auth, provider, logOut } from "../../config/firebase-config";
import { signInWithPopup } from "firebase/auth";
// import Content from "../../assets/images/Events/reg.png"
import { createUserWithEmailAndPassword } from "firebase/auth";

const Form = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [branch, setBranch] = useState("");
  const [roll, setRoll] = useState("");
  const [stud, setStud] = useState("");
  const [phone, setPhone] = useState("");
  // const [year, setYear] = useState("");
  const [gender, setGender] = useState("");
  const [hosteler, setHosteler] = useState("");
  const [loading, setLoading] = useState(false);
  const [mailerr, setMailerr] = useState(false);
  const [nameerr, setNameerr] = useState(false);
  // const [error, setError] = useState(null);
  // const [response, setResponse] = useState(null);
  const [phoneerr, setPhoneerr] = useState(false);
  // const [show, setShow] = useState(false);
  const [rollerr, setRollerr] = useState(false);
  const [studerr, setStuderr] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [hackerrank, setHackerrank] = useState("");
  const [hackerr, setHackerr] = useState(false);
  //const [unstop, setUnstop] = useState("");
  //const [unstoperr, setUnstoperr] = useState(false);
  const namepattern = /^[A-Za-z]{2}[A-za-z ]{0,28}$/gm;
  const emailpattern = /^[a-zA-Z]{2,20}23\d{5,7}@akgec\.ac\.in$/gm;
  const phonepattern = /^[5-9]\d{9}$/;
  const rollpattern = /^[2][3]([0-9]){11}$/;
  const studentpattern = /^[2][3]([0-9]){5,6}$/;
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const showToast = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const disableAKey = (event) => {
    if (event.ctrlKey) {
      event.preventDefault();
    }
  };

  // Add an event listener to the document
  useEffect(() => {
    document.addEventListener("keydown", disableAKey);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", disableAKey);
    };
  }, []);
  const disableContextMenu = (event) => {
    event.preventDefault();
  };

  // Add an event listener to the document
  useEffect(() => {
    document.addEventListener("contextmenu", disableContextMenu);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("contextmenu", disableContextMenu);
    };
  }, []);
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "Are you sure you want to leave this page?"; // This is for older browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // const history = useHistory();
  useEffect(() => {
    setEmail(props.email);
  }, [props.email]);
  // function ModalClose() {
  //   // setShow(false);
  //   logOut();
  //   setRegistered(true);
  //   setLoading(false);
  //   // history.push("/event/register");
  // }
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleCAP = useCallback(
    async (e) => {
      e.preventDefault();

      // Validate form inputs
      if (name.match(namepattern)) setNameerr(false);
      if (email.match(emailpattern)) setMailerr(false);
      if (phone.match(phonepattern)) setPhoneerr(false);
      if (stud.match(studentpattern)) setStuderr(false);
      if (roll.match(rollpattern)) setRollerr(false);

      // Check for all necessary inputs
      if (
        !name ||
        !roll ||
        !email ||
        !phone ||
        !branch ||
        !gender ||
        !stud ||
        !hosteler ||
        !hackerrank
      ) {
        showToast("Please Fill all the Entries");
        setLoading(false);
        return;
      } else if (!name.match(namepattern)) {
        setNameerr(true);
        showToast("Please Enter a Valid Name");
        setLoading(false);
        return;
      } else if (!email.match(emailpattern)) {
        setMailerr(true);
        showToast("Please Enter a Valid Email");
        setLoading(false);
        return;
      } else if (!phone.match(phonepattern)) {
        setPhoneerr(true);
        showToast("Please Enter a Valid Phone Number");
        setLoading(false);
        return;
      } else if (!stud.match(studentpattern)) {
        setStuderr(true);
        showToast("Please Enter a Valid Student ID");
        setLoading(false);
        return;
      } else if (!roll.match(rollpattern)) {
        setRollerr(true);
        showToast("Please Enter a Valid University Roll No.");
        setLoading(false);
        return;
      }

      // If validation passes, execute reCAPTCHA
      if (executeRecaptcha) {
        try {
          console.log("Executing reCAPTCHA");
          const gReCaptchaToken = await executeRecaptcha("FormSubmit");
          showResult(gReCaptchaToken); // Submit form after reCAPTCHA
        } catch (error) {
          showToast("reCAPTCHA execution failed");
          setLoading(false);
        }
      } else {
        showToast("reCAPTCHA not available");
        setLoading(false);
      }
    },
    [
      executeRecaptcha,
      name,
      email,
      phone,
      stud,
      roll,
      hackerrank,
      namepattern,
      emailpattern,
      phonepattern,
      studentpattern,
      branch,
      gender,
      hosteler,
      mailerr,
      nameerr,
      phoneerr,
      rollerr,
      rollpattern,
      isAuthenticated,
    ]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Perform Google Authentication if not already authenticated
      if (!isAuthenticated) {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        const authenticatedEmail = user.email;

        // Compare the authenticated email with the form email
        if (authenticatedEmail !== email) {
          showToast(
            "The email entered does not match the authenticated email."
          );
          setLoading(false);
          return;
        }

        setEmail(authenticatedEmail);
        setIsAuthenticated(true);
      }

      // Proceed with client-side form validation and reCAPTCHA
      handleCAP(e);
    } catch (error) {
      showToast("Authentication or Form Submission Failed");
      setLoading(false);
    }
  };

  function showResult(gReCaptchaToken) {
    // e.preventDefault();

    axios({
      method: "post",
      url: `${process.env.REACT_APP_RECRUITMENT_KEY}/v1/user/register`,
      // url: `http://localhost:5000/v1/user/register`,

      // headers: {
      //   "Access-Control-Allow-Origin":
      //     process.env.REACT_APP_RECRUITMENT_KEY,
      //   "Access-Control-Allow-Headers":
      //     "Content-Type, Accept, Access-Control-Allow-Origin, Authorization",
      //   "Access-Control-Expose-Headers": "",
      //   "Access-Control-Max-Age": "21600",
      //   Allow: "OPTIONS, HEAD, DELETE, POST, GET",
      //   Vary: "origin",
      //   "Content-Type": "application/json",
      //   Authorization: `bearer ${props.token}`,
      // },
      data: {
        name: name,
        email: email,
        stdno: Number(stud),
        rollno: Number(roll),
        branch: branch,
        phone: phone,
        hosteller: hosteler,
        gender: gender,
        reCaptcha: gReCaptchaToken,
        hackerrank: hackerrank,
      },
    })
      .then((result) => {
        // console.log(result);
        setLoading(false);
        // setResponse(result.data.message);
        setRegistered(true);
        sessionStorage.setItem("thankyou", true);
        toast.success(`Registration Successful`);
        logOut();
      })
      .catch((err) => {
        showToast("Registration Failed");
        // console.log(err);
        setLoading(false);
        // setError(err);
        // setShow(true);
      });

    // console.log(typeof(name), typeof(email),typeof(year),typeof(roll),typeof(branch), typeof(phone), typeof(hosteler),typeof(gender));
  }
  useEffect(() => {
    let thankyou = sessionStorage.getItem("thankyou");
    if (thankyou) {
      setRegistered(true);
    }
  }, []);

  let styles = classes;
  var color = String();

  if (props.theme) {
    color = "black";
    styles = classes;
  } else {
    color = "white";
    styles = classesDark;
  }
  var m;
  if (styles === classesDark) {
    m = "dark";
  } else {
    m = "light";
  }
  const darkTheme = createTheme({
    palette: {
      mode: m,
    },
  });

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        limit={5}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {registered ? (
        <SuccessPage theme={props.theme} />
      ) : (
        <>
          <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <div className={styles.forbg}>
              {/* <img src={Content}></img> */}
              <section className={styles.formSection}>
                <div className={styles.page}>
                  
                  <Container fixed className={styles.formContainer}>
                    <div className={styles.head}>
                      <img src={logo} alt="logo"></img>
                      <h1>RECRUITMENT DRIVE '24</h1>
                      {/* <p>Hello!</p>
        <p>We are glad to see you :)</p> */}
                    </div>
                    <Box
                      component="form"
                      sx={{ "& > :not(style)": { m: 2, width: "30ch" } }}
                      noValidate
                      autoComplete="off"
                      className={styles.input}
                    >
                      <TextField
                        required
                        id="outlined-required"
                        label="Full Name"
                        variant="outlined"
                        type="name"
                        error={nameerr}
                        helperText={nameerr ? "Enter Correct Name" : ""}
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          const namepattern = /^[A-Za-z]{2}[A-za-z ]{0,28}$/gm;
                          setNameerr(!namepattern.test(e.target.value));
                        }}
                        sx={{ color: color }}
                      />
                      <TextField
                        required
                        id="outlined-required"
                        label="College Email"
                        variant="outlined"
                        type="email"
                        error={mailerr}
                        helperText={mailerr ? "Enter Correct Email" : ""}
                        value={props.email}
                        //disabled
                        onChange={(e) => {
                          setEmail(e.target.value);
                          const emailpattern =
                            /^[a-zA-Z]{2,20}23\d{5,7}@akgec\.ac\.in$/gm;
                          setMailerr(!emailpattern.test(e.target.value));
                        }}
                        sx={{ color: color }}
                      />
                    </Box>

                    <Box
                      component="form"
                      sx={{ "& > :not(style)": { m: 2, width: "30ch" } }}
                      noValidate
                      autoComplete="off"
                      className={styles.input}
                    >
                      <TextField
                        required
                        id="outlined-select-currency"
                        label="Branch"
                        variant="outlined"
                        select
                        type="branch"
                        defaultValue=""
                        value={branch}
                        onChange={(e) => setBranch(e.target.value)}
                      >
                        <MenuItem value={"CSE"}>CSE</MenuItem>
                        <MenuItem value={"CS"}>CS</MenuItem>
                        <MenuItem value={"CSE-AIML"}>CSE(AIML)</MenuItem>
                        <MenuItem value={"CSE-DS"}>CSE(DS)</MenuItem>
                        <MenuItem value={"CS-HINDI"}>CS-Hindi</MenuItem>
                        <MenuItem value={"AIML"}>AIML</MenuItem>

                        <MenuItem value={"IT"}>IT</MenuItem>
                        <MenuItem value={"CSIT"}>CSIT</MenuItem>
                        <MenuItem value={"ECE"}>ECE</MenuItem>
                        <MenuItem value={"EN"}>EN</MenuItem>
                        <MenuItem value={"ME"}>ME</MenuItem>
                        <MenuItem value={"CE"}>CIVIL</MenuItem>
                      </TextField>
                      <TextField
                        required
                        id="outlined-required"
                        label="Student No."
                        variant="outlined"
                        type="stud"
                        error={studerr}
                        helperText={studerr ? "Enter Correct Roll No." : ""}
                        value={stud}
                        onChange={(e) => {
                          setStud(e.target.value);
                          const studpattern = /^[2][3]([0-9]){5,6}$/;
                          setStuderr(!studpattern.test(e.target.value));
                        }}
                      />

                      {/* <TextField
                        required
                        id="outlined-select-currency"
                        label="Year"
                        select
                        defaultValue=""
                        variant="outlined"
                        type="year"
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                      >
                        <MenuItem value={1}>1st</MenuItem>
                        <MenuItem value={2}>2nd</MenuItem>
                      </TextField> */}
                    </Box>

                    <Box
                      component="form"
                      sx={{ "& > :not(style)": { m: 2, width: "30ch" } }}
                      noValidate
                      autoComplete="off"
                      className={styles.input}
                    >
                      <TextField
                        required
                        id="outlined-required"
                        label="University Roll No."
                        variant="outlined"
                        type="roll"
                        error={rollerr}
                        helperText={rollerr ? "Enter Correct Roll No." : ""}
                        value={roll}
                        onChange={(e) => {
                          setRoll(e.target.value);
                          const rollpattern = /^[2][3]([0-9]){11}$/;
                          setRollerr(!rollpattern.test(e.target.value));
                        }}
                      />
                      <TextField
                        required
                        id="outlined-required"
                        label="Contact Number"
                        InputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          startAdornment: (
                            <InputAdornment position="start">
                              +91
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        type="phone"
                        error={phoneerr}
                        helperText={
                          phoneerr ? "Enter Correct Phone Number " : ""
                        }
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                          const phonepattern = /^[5-9]\d{9}$/gi;
                          setPhoneerr(!phonepattern.test(e.target.value));
                        }}
                      />
                    </Box>
                    <Box
                      component="form"
                      sx={{ "& > :not(style)": { m: 2, width: "30ch" } }}
                      noValidate
                      autoComplete="off"
                      className={styles.input}
                    >
                      <TextField
                        required
                        id="outlined-select-currency"
                        label="Gender"
                        select
                        defaultValue=""
                        variant="outlined"
                        type="gender"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <MenuItem value={"Male"}>Male</MenuItem>
                        <MenuItem value={"Female"}>Female</MenuItem>
                        <MenuItem value={"Both"}>Others</MenuItem>
                      </TextField>
                      {/* <FormControl>
                        <FormLabel
                          required
                          id="demo-row-radio-buttons-group-label"
                        >
                          Gender
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          defaultValue=""
                          // value={gender}
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <FormControlLabel
                            value="female"
                            control={
                              <Radio
                                color={
                                  styles === classes ? "primary" : "default"
                                }
                              />
                            }
                            label="Female"
                          />
                          <FormControlLabel
                            value="male"
                            control={
                              <Radio
                                color={
                                  styles === classes ? "primary" : "default"
                                }
                              />
                            }
                            label="Male"
                          />
                          <FormControlLabel
                            value="other"
                            control={
                              <Radio
                                color={
                                  styles === classes ? "primary" : "default"
                                }
                              />
                            }
                            label="Others"
                          />
                        </RadioGroup>
                      </FormControl> */}

                      <TextField
                        required
                        id="outlined-select-currency"
                        label="Hosteler"
                        select
                        defaultValue={"No"}
                        variant="outlined"
                        type="hosteler"
                        value={hosteler}
                        onChange={(e) => setHosteler(e.target.value)}
                      >
                        <MenuItem value={"Yes"}>Yes</MenuItem>
                        <MenuItem value={"No"}>No</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      component="form"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        "& > :not(style)": {
                          m: 1,
                          width: "30ch",
                        },
                      }}
                      noValidate
                      autoComplete="off"
                      className={styles.input}
                    >
                      {/* <TextField
                        required
                        id="outlined-required"
                        label="Unstop Username"
                        variant="outlined"
                        type="unstop"
                        error={unstoperr}
                        helperText={unstoperr ? "Enter Correct Unstop ID" : ""}
                        value={unstop}
                        onChange={(e) => {
                          setUnstop(e.target.value);
                          setUnstoperr(e.target.value.length < 2);
                        }}
                        sx={{ color: color }}
                      /> */}
                      <TextField
                        required
                        id="outlined-required"
                        label="Hackerrank Username"
                        variant="outlined"
                        type="hackerrank"
                        error={hackerr}
                        helperText={
                          hackerr ? "Enter Correct Hacker Rank ID" : ""
                        }
                        value={hackerrank}
                        onChange={(e) => {
                          setHackerrank(e.target.value);
                          setHackerr(e.target.value.length < 2);
                        }}
                        sx={{ color: color }}
                      />
                    </Box>
                    <div className={styles.buttonRow}>
                      <Button
                        onClick={handleSubmit}
                        disabled={loading}
                        variant="contained"
                        style={{ textAlign: "center" }}
                        sx={
                          {
                            // margin:"1.75em 0",
                          }
                        }
                      >
                        {loading ? <>Pending...</> : <>Register</>}
                      </Button>
                    </div>
                  </Container>
                </div>
              </section>
            </div>
            {/* <Modal show={show} className={styles.modal}>
              <Modal.Body>
                {error}
                <br />
                {response}
              </Modal.Body>
              <Modal.Footer>
                <button className={styles.Modalbtn} onClick={ModalClose}>
                  Close
                </button>
              </Modal.Footer>
            </Modal> */}
          </ThemeProvider>
          {/* </GoogleReCaptchaProvider>  */}
        </>
      )}
      <Footer theme={props.theme} />
    </>
  );
};

export default Form;
